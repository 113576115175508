<template>
  <div class="content">
    <div v-for="item in list" :key="item.id" class="box">
      <div class="wrap" :style="{border: `3px solid ${item.injury ? '#f0746c' : '#60BF52' }`}">
        <div class="img_box">
          <img :src="$utils.defaultAvatar(item.avatar)" />
        </div>
      </div>
      <p><span class="akrobatRegular">{{ item.number || defaultText }}</span>{{ item.name }}</p>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const state = reactive({
      defaultText: "-",
    });

    return {
      ...props,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="scss">
.content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  .box {
    margin-right: 100px;
    margin-bottom: 30px;
    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 110px;
      width: 110px;
      border-radius: 50%;
      box-sizing: border-box;
      overflow: hidden;
      .img_box {
        display: flex;
        align-items: flex-start;
        background: #222222;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid #0f1118;
        box-sizing: border-box;
        img {
          width: 100%;
        }
      }
    }
    p {
      margin-top: 16px;
      font-size: 24px;
      text-align: center;
      color: #fff;
      span {
        padding-right: 6px;
        font-size: 26px;
      }
    }
  }
}
</style>
