<template>
  <div class="coach_content">
    <div v-for="item in list" :key="item.id" class="coach_box">
      <div class="wrap">
        <img :src="$utils.defaultAvatar(item.avatar)" />
      </div>
      <p class="name">{{ item.name }}</p>
      <p class="title">{{ item.title || defaultText }}</p>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs } from "vue";

export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  setup(props) {
    const state = reactive({
      defaultText: "-",
    });
    return {
      ...props,
      ...toRefs(state),
    };
  },
};
</script>

<style scoped lang="scss">
.coach_content {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;

  .coach_box {
    width: 108px;
    margin-right: 50px;
    margin-bottom: 30px;
    .wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 100px;
      background-color: #16191e;
      border-radius: 50%;
      overflow: hidden;
      img {
        width: 100%;
      }
    }
    p {
      width: 108px;
      color: #fff;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .name {
      margin-top: 10px;
      font-size: 24px;
      line-height: 34px;
    }
    .title {
      margin-top: 10px;
      font-size: 18px;
      opacity: 0.8;
    }
  }
}
</style>
