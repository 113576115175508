<template>
    <!-- 导航 -->
    <page-head title="球队总结" />
    <content-bg>
        <template v-slot:content>
            <div class="mian" ref="mianRef">
                <div ref="contentRef">
                    <div class="row">
                        <h1 class="title">教练组</h1>
                        <coachList :list="teamReport.staff" />
                        <no-data v-if="teamReport.staff && teamReport.staff.length === 0" />
                    </div>
                    <div class="row">
                        <h1 class="title">
                            球员<span style="color: #60BF52;" class="akrobatBold">{{ teamReport.player ? teamReport.player.total : 0 }}</span>人
                        </h1>
                        <div class="playerList">
                            <div class="playerItem" v-for="item in playerPosition" :key="item.value">
                                <p class="label">{{ item.label }}</p>
                                <p class="num akrobatBold" :style="{'color': item.color}">
                                    {{ teamReport.player ? teamReport.player[item.value] : 0 }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h1 class="title">
                            伤病<span style="color: #F0746C;" class="akrobatBold">{{ teamReport.injuryTotal || 0 }}</span>人
                        </h1>
                        <injuryList :list="teamReport.injuryPlayers" />
                    </div>
                    <div class="row">
                        <div class="total">
                            <div class="statistics" v-for="item in statistics" :key="item.key">
                                <div class="icon">
                                    <img :src="item.icon" alt="">
                                </div>
                                <div class="text">
                                    <p class="label">{{ item.label }}</p>
                                    <p class="num">
                                        <span class="akrobatBold">{{ teamReport[item.key] ? teamReport[item.key] : 0 }}</span>
                                        {{ item.unit }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </content-bg>
    <!-- 翻页 -->
    <pagination-com class="pagination" :current-page="query.page" :total-page="query.totalPage" @changePage="changePage"></pagination-com>
</template>

<script>
import {getCurrentInstance, reactive, toRefs, onMounted, nextTick, watch} from "vue";
import { useStore } from "vuex";
import coachList from './components/coachList.vue';
import injuryList from './components/injuryList.vue';

export default {
    components: {
        coachList,
        injuryList,
    },
    setup() {
        const store = useStore();
        const { proxy } = getCurrentInstance()
        const state = reactive({
            mianRef: null,
            contentRef: null,
            teamReport: {},
            // 球员
            playerPosition: [],
            statistics: [
                {
                    key: 'trainingDays',
                    label: '训练天数',
                    icon: require('@i/teamSummary/trainingDays.svg'),
                    unit: '天'
                },
                {
                    key: 'trainingDuration',
                    label: '训练时长',
                    icon: require('@i/teamSummary/trainingDuration.svg'),
                    unit: '小时'
                },
                {
                    key: 'matches',
                    label: '比赛场次',
                    icon: require('@i/teamSummary/matches.svg'),
                    unit: '场'
                },
                {
                    key: 'trainingVideos',
                    label: '训练视频',
                    icon: require('@i/teamSummary/trainingVideos.svg'),
                    unit: '个'
                },
                {
                    key: 'playerEval',
                    label: '球员评估',
                    icon: require('@i/teamSummary/playerEval.svg'),
                    unit: '份'
                },
                {
                    key: 'gpsReports',
                    label: 'GPS报告',
                    icon: require('@i/teamSummary/gpsReports.svg'),
                    unit: '份'
                },
                {
                    key: 'exercises',
                    label: '训练教案',
                    icon: require('@i/teamSummary/exercises.svg'),
                    unit: '份'
                },
                {
                    key: 'physicalTests',
                    label: '体测报告',
                    icon: require('@i/teamSummary/physicalTests.svg'),
                    unit: '份'
                },
                {
                    key: 'questionnaires',
                    label: '球员自测',
                    icon: require('@i/teamSummary/questionnaires.svg'),
                    unit: '份'
                }
            ],
            query: {
                page: 1,
                totalPage: 1
            },
        });

        watch(() => store.state.identifyData, (val, old) => {
            // console.log(val, old);
            if (!val.type) return false;
            const params = {
                ...val,
                ...state.query,
            }
            proxy.$utils.identifyFn({
                type: val.type,
                data: params,
                changePage: changePage,
            })
        })

        const getData = async () => {
            const { id } = store.state.teamData;
            const params = {
                teamId: id, // 球队id
            }
            const { code, data } = await proxy.$server.teamReport(params);
            if (code === 200) {
                const playerPositionList = store.state.playerPositionList

                state.playerPosition = playerPositionList.map(item => {
                    // 中场
                    if(item.value === 'midfielder') {
                        item.color = '#60BF52'
                    }
                    // 后卫
                    if(item.value === 'defender') {
                        item.color = '#F0746C'
                    }
                    // 守门员
                    if(item.value === 'goalkeeper') {
                        item.color = '#F0BC00'
                    }
                    // 前锋
                    if(item.value === 'forward') {
                        item.color = '#42A5F5'
                    }
                    return item
                })
                state.teamReport = data;

                // for (let i = 0; i < state.teamReport.staff.length; i++) {
                //     for (let j = 0; j < state.coachList.length; j++) {
                //         if (state.teamReport.staff[i].title == state.coachList[j].key) {
                //             state.coachList[j].list.push(state.teamReport.staff[i]);
                //         }
                //     }
                // }
                // 计算需要分几页
                nextTick(() => {
                    const mianDom = state.mianRef.offsetHeight
                    const contentDom = state.contentRef.offsetHeight
                    const totalPage = Math.ceil(contentDom / mianDom)
                    state.query.totalPage = totalPage
                })
            }
        }

        const changePage = (page) => {
            const mianDom = state.mianRef
            const height = mianDom.offsetHeight
            if(page > state.query.page) {
                // 下一页
                mianDom.scrollTop = height * state.query.page;
            } else {
                // 上一页
                mianDom.scrollTop = height * (page - 1);
            }
            state.query.page = page
        }

        onMounted(() => {
            getData();
        });

        return {
            changePage,
            ...toRefs(state),
        }
    }
}
</script>

<style scoped lang="scss">
.mian {
    padding: 50px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow: auto;
    .row {
        margin-bottom: 24px;
        .title {
            font-weight: 500;
            font-size: 28px;
            color: #FFFFFF;
            span {
                padding: 0 6px;
                font-weight: 700;
                font-size: 46px;
            }
        }
        .playerList {
            margin-top: 30px;
            display: flex;
            .playerItem {
                margin-right: 30px;
                width: 197px;
                height: 212px;
                background-image: url("~@i/teamSummary/playerBag.png");
                background-size: 100%;
                .label {
                    height: 62px;
                    font-weight: 500;
                    font-size: 24px;
                    line-height: 62px;
                    text-align: center;
                    color: #FFFFFF;
                }
                .num {
                    font-weight: 700;
                    font-size: 50px;
                    line-height: 120px;
                    text-align: center;
                    color: #60BF52;
                }
            }
        }
        .total {
            margin-top: 53px;
            display: grid;
            grid-template-columns: repeat(3, 380px);
            grid-template-rows: auto;
            justify-content: space-between;
            .statistics {
                display: flex;
                align-items: flex-start;
                padding: 24px 30px;
                margin-bottom: 30px;
                width: 380px;
                height: 136px;
                box-sizing: border-box;
                border: 1px solid rgba(255, 255, 255, 0.2);
                .icon {
                    margin-right: 16px;
                    width: 30px;
                    height: 30px;
                    img {
                        width: 100%;
                    }
                }
                .text {
                    color: rgba(255, 255, 255, 0.6);
                    .label {
                        margin-bottom: 10px;
                        font-size: 25px;
                        line-height: 35px;
                    }
                    .num {
                        font-size: 20px;
                        span {
                            font-size: 48px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}
.pagination {
    position: absolute;
    z-index: 1;
    top: 1000px;
    left: 590px;
}
</style>